import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from 'components/common/Icon';
import { space, flexbox } from 'styled-system';
import {
	ButtonWrapper,
	StyledButtonBase,
	ButtonContentWrapper,
	ButtonBackgroundOverlay,
} from './styles';
import * as variants from './variants';

export const ButtonBase = ({
	icon,
	text,
	size,
	variant,
	onClick,
	htmlType,
	disabled,
	fullWidth,
	iconRight,
	...rest
}) => {
	const iconComponent = <Icon size={20} icon={icon} />;

	const buttonContent = (
		<ButtonContentWrapper iconRight={iconRight}>
			{icon && iconComponent}
			{text}
		</ButtonContentWrapper>
	);

	return (
		<ButtonWrapper
			variant={variant}
			disabled={disabled}
			fullWidth={fullWidth}
			{...rest}
		>
			<StyledButtonBase
				size={size}
				onClick={onClick}
				block={fullWidth}
				htmlType={htmlType}
				disabled={disabled}
			>
				<ButtonBackgroundOverlay className='button-bg-overlay'>
					{buttonContent}
				</ButtonBackgroundOverlay>
				{buttonContent}
			</StyledButtonBase>
		</ButtonWrapper>
	);
};

ButtonBase.propTypes = {
	text: PropTypes.string,
	icon: PropTypes.string,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	fullWidth: PropTypes.bool,
	iconRight: PropTypes.bool,
	size: PropTypes.oneOf(['sm', 'md']),
	variant: PropTypes.oneOf(Object.keys(variants)),
	htmlType: PropTypes.oneOf(['submit', 'reset', 'button']),
};

ButtonBase.defaultProps = {
	text: '',
	icon: '',
	size: 'md',
	disabled: false,
	fullWidth: false,
	iconRight: false,
	onClick: () => {},
	variant: 'primary',
	htmlType: 'button',
};

export const Button = styled(ButtonBase)`
	${space}
	${flexbox}
`;
