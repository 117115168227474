import { theme } from 'theme/theme';
import styled from 'styled-components';
import { css } from '@styled-system/css';
import { Button as AntButton } from 'antd';
import { FlexBox } from 'components/common/FlexBox';
import { variant as styledSystemVariant } from 'styled-system';
import { light, primary, loading, secondary, transparent } from './variants';

export const buttonBorderRadius = '32px';

export const ButtonWrapper = styled.div(
	({ disabled, fullWidth }) =>
		css({
			padding: '1px',
			borderRadius: buttonBorderRadius,

			opacity: disabled && '0.4',
			cursor: disabled && 'no-entry',
			width: fullWidth ? '100%' : 'fit-content',
		}),
	styledSystemVariant({
		prop: 'variant',
		variants: {
			light,
			primary,
			loading,
			secondary,
			transparent,
		},
	})
);

export const ButtonBackgroundOverlay = styled.div(
	css({
		opacity: 0,
		py: 'inherit',
		px: 'inherit',
		width: '100%',
		height: '100%',
		display: 'flex',
		position: 'absolute',
		justifyContent: 'center',
		transition: 'opacity 0.3s ease-out',

		'&:hover': {
			opacity: 1,
		},
	})
);

export const StyledButtonBase = styled(AntButton)(({ size }) =>
	css({
		border: 'none',
		display: 'flex',
		lineHeight: '19px',
		overflow: 'hidden',
		alignItems: 'center',
		position: 'relative',
		height: 'fit-content',
		letterSpacing: '0.1em',
		justifyContent: 'center',
		fontSize: theme.fontSizes[0],
		py: size === 'sm' ? 'sm' : 'md',
		px: size === 'sm' ? 'sm' : 'xl',
		borderRadius: buttonBorderRadius,
	})
);

export const ButtonContentWrapper = styled(FlexBox)(({ iconRight }) =>
	css({
		gap: 'xs',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: iconRight ? 'row-reverse' : 'row',
	})
);
